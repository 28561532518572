import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'ng-marquee',
  template: `<div class="ng-marquee-wrapper">
  <div>
      <ng-content></ng-content>
  </div>
</div>`,
  styles: [`.ng-marquee-wrapper{overflow:hidden;position:relative}.ng-marquee-wrapper>div{height:100%;display:inline-block;margin:0;padding-left:100%;-webkit-animation:20s linear infinite marqueeAnimation;animation:20s linear infinite marqueeAnimation;white-space:nowrap}.ng-marquee-wrapper:hover>div{-webkit-animation-play-state:paused;animation-play-state:paused}@-webkit-keyframes marqueeAnimation{0%{-webkit-transform:translateX(0);transform:translateX(0)}100%{-webkit-transform:translateX(-100%);transform:translateX(-100%)}}@keyframes marqueeAnimation{0%{-webkit-transform:translateX(0);transform:translateX(0)}100%{-webkit-transform:translateX(-100%);transform:translateX(-100%)}}@media (max-width:500px){.ng-marquee-wrapper>div{width:100%;height:100%;margin:0;-webkit-transform:translateX(100%);transform:translateX(100%);-webkit-animation:9s linear 1s infinite marqueeAnimation;animation:9s linear 1s infinite marqueeAnimation;white-space:nowrap}}`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NgMarqueeComponent implements OnInit {

  @Input() duration: string | number;
  animationElem: HTMLDivElement;

  constructor(
  ) { }

  updateDuration() {
    if (!this.duration) {
      return;
    }
    this.animationElem.style.animationDuration = `${this.duration}s`;
  }

  ngOnInit() {
    this.animationElem = document.querySelectorAll('.ng-marquee-wrapper > div')[0] as HTMLDivElement;
    this.updateDuration();
  }

}
