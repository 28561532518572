import { NgModule } from '@angular/core';
import { NgMarqueeComponent } from './ng-marquee/ng-marquee.component';

@NgModule({
  imports: [
  ],
  declarations: [NgMarqueeComponent],
  exports: [NgMarqueeComponent]
})
export class NgMarqueeModule { }
